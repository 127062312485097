import ShopService from '@/services/api/shop.service';
import { createSlice } from '@reduxjs/toolkit';

export const posSlice = createSlice({
  name: "pos",
  initialState: {
    loading: false,
    products: [],
    removeProductList: [],
    removeProductQuantity: [],
    shopInfo: null,
    totalPrice: 0,
    subtotal: 0,
    paidTotal: 0,
    vatTotal: 0,
    dueTotal: 0,
    preDueTotal: 0,
    delivery_charge: 0,
    payment_method: null,
    bank: null,
    customer: null,
    customerID: null,
    warehouse: null,
    bankData: [],
  },
  reducers: {
    initPosData: (state, action) => {
      const data = action.payload;

      return {
        ...state,
        dueTotal: parseFloat(data.due).toFixed(2),
      };
    },

    updateProducts: (state, action) => {
      const data = action.payload;
      const exPinfo = state.products.find((x) => x.id == data?.id);

      if (exPinfo) {
        exPinfo.quantity++;
      } else {
        const itemInfo = {
          id: data?.id,
          product_code: data?.product_code,
          name: data?.name,
          unitPrice: data?.sales_price,
          product_discount: data?.discount_amount,
          quantity: 1,
        };
        state.products.push(itemInfo);
      }
    },

    setProductsMeta: (state, action) => {
      const productId = action.payload.id;
      const productQty = action.payload.qty;
      const productPrice = action.payload.price;
      // const productDiscount = action.payload.price;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: productQty, unitPrice: productPrice };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },
    incrementProduct: (state, action) => {
      const productId = action.payload;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: product.quantity + 1 };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    setProductQty: (state, action) => {
      const productId = action.payload.product?.id;
      const productQty = action.payload.quentity;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: Number(productQty) };
        }

        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    decrementProduct: (state, action) => {
      const productId = action.payload;

      const updatedProducts = state.products.map((product) => {
        if (product.id === productId && product.quantity > 1) {
          return { ...product, quantity: product.quantity - 1 };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
        removeProductQuantity: [...state.removeProductQuantity, productId],
      };
    },
    removeProduct: (state, action) => {
      const productId = action.payload;

      const updatedProducts = state.products.filter(
        (product) => product.id !== productId
      );

      return {
        ...state,
        products: updatedProducts,
        removeProductList: [...state.removeProductList, productId],
      };
    },

    removeAllProduct: (state, action) => {
      // const productId = action.payload;

      // const updatedProducts = state.products.filter(
      //   (product) => product.id !== productId
      // );

      return {
        ...state,
        products: [],
        // removeProductList: [...state.removeProductList, productId],
      };
    },

    fetchShopInfo: (state, action) => {
      return {
        ...state,
        shopInfo: action.payload,
      };
    },
    calcPrices: (state) => {
      let returningStates = {
        subtotal: 0,
        vatTotal: 0,
        total: 0,
        discount: 0,
      };

      const products = [...state.products];

      let subtotal = 0;
      const vat = state.shopInfo?.vat || 0;

      products.map((val, idx) => {
        subtotal +=
          val.quantity * val.unitPrice - val.product_discount * val.quantity;
      });
      returningStates.subtotal = subtotal;

      if (vat) {
        returningStates.vatTotal = (subtotal * vat) / 100;
      }

      returningStates.total = subtotal + returningStates.vatTotal;
      returningStates.total = Math.round(returningStates.total);

      return {
        ...state,
        subtotal: parseFloat(returningStates.subtotal).toFixed(2),
        vatTotal: parseFloat(returningStates.vatTotal).toFixed(2),
        totalPrice: parseFloat(returningStates.total).toFixed(2),
      };
    },
    recordPayment: (state, action) => {
      const paidAmount = action.payload;
      const _due = state.dueTotal - paidAmount;

      return {
        ...state,
        paidTotal: paidAmount,
        dueTotal: _due <= 0 ? 0 : _due,
      };
    },
    updatePaidTotal: (state, action) => {
      const paidAmount = action.payload;

      return {
        ...state,
        paidTotal: paidAmount,
      };
    },
    updateTotalDiscount: (state, action) => {
      const discount = action.payload;

      return {
        ...state,
        totalDiscount: discount,
      };
    },
    updateDueTotal: (state, action) => {
      const _due = action.payload;

      return {
        ...state,
        dueTotal: parseFloat(_due || 0).toFixed(2),
      };
    },
    updatePaymentMethod: (state, action) => {
      return {
        ...state,
        payment_method: action.payload,
      };
    },
    updatePaymentBank: (state, action) => {
      return {
        ...state,
        bank: action.payload,
      };
    },
    updateCustomer: (state, action) => {
      return {
        ...state,
        customer: action.payload,
      };
    },
    updateCustomerID: (state, action) => {
      return {
        ...state,
        customerID: action.payload,
      };
    },
    updateWarehouse: (state, action) => {
      return {
        ...state,
        warehouse: action.payload,
      };
    },
    saveSell: (state, action) => {},
    clearSale: (state, action) => {
      return {
        ...state,
        products: [],
        removeProductList: [],
        removeProductQuantity: [],
        // shopInfo: null,
        totalPrice: 0,
        subtotal: 0,
        paidTotal: 0,
        totalDiscount: 0,
        dueTotal: 0,
        vatTotal: 0,
        delivery_charge: 0,
        payment_method: null,
        bank: null,
        customer: null,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },

    decreaseProduct: (state, action) => {
      const _product = action.payload;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === _product.Id) {
          return {
            ...product,
            quantity: _product.quantity,
            stock: product.stock - _product.quantity,
          };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    decreaseupdateProducts: (state, action) => {
      const data = action.payload;

      const exPinfo = state.products.find((x) => x.id == data?.id);

      if (exPinfo) {
        exPinfo.quantity++;
      } else {
        const itemInfo = {
          id: data?.id,
          product_code: data?.product_code,
          name: data?.name,
          unitPrice: data?.sales_price,
          product_discount: data?.discount_amount,
          stock: data?.stock,
        };
        state.products.push(itemInfo);
      }
    },
  },
});

export const {
  initPosData,
  updateProducts,
  setProductsMeta,
  incrementProduct,
  decrementProduct,
  removeProduct,
  removeAllProduct,
  fetchShopInfo,
  calcPrices,
  recordPayment,
  updatePaidTotal,
  updateTotalDiscount,
  updateDueTotal,
  updatePaymentMethod,
  updatePaymentBank,
  saveSell,
  updateCustomer,
  updateCustomerID,
  updateWarehouse,
  clearSale,
  setLoading,
  decreaseProduct,
  decreaseupdateProducts,
  setProductQty,
} = posSlice.actions;

export default posSlice.reducer;
